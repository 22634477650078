export const parseTimeSeries = (string) => {
  if (!string) {
    return {
      state: "empty",
      series: [],
    };
  }
  const series = [];
  try {
    const lines = string.split("\n");
    lines.forEach((line) => {
      const values = line.split("\t");
      if (values.length !== 3) {
        series.push({
          label: "invalid Line:",
          contribution: line,
          receiving: "",
        });
      } else {
        series.push({
          label: values[0],
          contribution: values[1],
          receiving: values[2],
        });
      }
    });
  } catch (e){
    return {
      state: "error",
      series: []
    }
  }

  return {
    state: "filled",
    series,
  };
};
