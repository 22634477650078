import { createAction } from '@reduxjs/toolkit'
import { requestPasswordChange } from '../services/account.js'
import { enqueueSnackbar } from '../../../genericPages/notifications/state/actions'
import { SuccessNotification, ErrorNotification } from '../../../core/sharedMethods/notifications.js'

export const updateField = createAction('account/updateField', function prepare({field, data}){
	return {
		payload: {
			field,
			data
		}
	}
})

export const changePasswordRequested = createAction('account/changePasswordRequested')
export const changePasswordFailure = createAction('account/changePasswordFailure')
export const changePasswordSuccess = createAction('account/changePasswordSuccess')

export function doRequestChangePassword(currentPassword, newPassword, passwordConfirmation) {
	return function (dispatch, getState, api) {
		const { auth } = getState()
		dispatch(changePasswordRequested())
		requestPasswordChange(api, auth.token)(currentPassword, newPassword, passwordConfirmation, auth.id)
		.then(({data, status})  => {
			dispatch(enqueueSnackbar(SuccessNotification("Senha alterada com sucesso")))
			dispatch(changePasswordSuccess())
		}).catch(err=>{
			dispatch(enqueueSnackbar(ErrorNotification("Não foi possível alterar sua senha. Tente novamente.")))
			dispatch(changePasswordFailure())
		})
	}
}

