import React, { Component } from "react";
import { Route, Switch } from 'react-router-dom';
import { AppRoutes as AppRoutes } from '../core/routes';
import { Signin } from '../genericPages/login/containers/signin';


export class AuthRouter extends Component {
  render() {
    return (
      <Switch>
        <Route path={AppRoutes.login}>
          <Signin />
        </Route>
      </Switch>
    );
  }
}

