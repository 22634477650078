import {
  doRequestMe,
  logoff,
} from "genericPages/login/state/actions";
import React, { Component } from "react";
import "App/App.css";
import { Bar } from "ui-lib";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import { Redirect, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { LoadingFrame } from "ui-lib";
import {
  AppRoutes as AppRoutes,
  rootAuthenticatedRoute,
} from '../core/routes';
import { ProtectedRoute } from "ui-lib";
import { UserRouter } from "../pages/Admin/pages/user/userRouter";
import Box from "@material-ui/core/Box";
import { AdminRouter } from "../pages/Admin/pages/user/adminRouter"

const styles = () => ({
  root: {
    backgroundColor: "white",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
});

class appRouter extends Component {
  componentDidMount() {
    const { meRequestStatus } = this.props.auth;
    if (meRequestStatus === "none") {
      if (this.props.token) {
        this.props.doRequestMe(this.props.history, this.props.token);
      }
    }
  }

  renderLoading(meRequestStatus) {
    if (meRequestStatus === "loading") {
      return <LoadingFrame />;
    }
  }

  renderContent(name, loggedIn,  meRequestStatus) {
    if (meRequestStatus === "success") {
      return (
        <>
          <Bar
            redirect={this.props.history.replace}
            onMyAccountClick={() => alert('ainda não implementado, fale com o João')}
            AppRoutes={AppRoutes}
            logoff={this.props.logoff}
            name={name}
            roles={'consultant'}
            rootRoute={rootAuthenticatedRoute()}
          />
          <Box display={"flex"} paddingTop={"64px"} width={"100vm"}>
            <Switch>
              <ProtectedRoute
                token={this.props.token}
                path={AppRoutes.users}
                loggedIn={loggedIn}
              >
                <AdminRouter />
              </ProtectedRoute>
              <Redirect to={AppRoutes.users} />
            </Switch>
          </Box>
        </>
      );
    }
  }

  render() {
    const { name, loggedIn, meRequestStatus } = this.props.auth;
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        minHeight={"100vh"}
        style={{ backgroundColor: "white" }}
      >
        <CssBaseline />
        {this.renderLoading(meRequestStatus)}
        {this.renderContent(name, loggedIn,  meRequestStatus)}
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doRequestMe,
      logoff,
    },
    dispatch
  );
};

export const AppRouter = withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(appRouter)
  )
);
