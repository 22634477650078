import Typography from '@material-ui/core/Typography'
import React, {Component} from 'react'
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import { doRequestAdminUser, adminUserReset  } from './state/actions.js'
import { ConditionalLoading } from '../../../../../components/ConditionalLoading.js'
import { withRouter } from 'react-router-dom'
import { UserEditionRouter } from './UserEditionRouter.js'
import Box from "@material-ui/core/Box"

class Index extends Component {

	getIdFromUrl(props){
		const arrayPath = props.location.pathname.split("/")
		return arrayPath[2]
	}

	componentDidMount() {
		if(this.props.status === 'none'){
			this.props.doRequestAdminUser(this.getIdFromUrl(this.props))
		}
	}

	componentWillUnmount() {
		this.props.adminUserReset()
	}

	render() {
		return (
			<ConditionalLoading state={this.props.status}>
				<Box display={'flex'} flexDirection={'column'} width={'100%'}>
				<Typography style={{padding: 20}} variant="h6" gutterBottom>
					Usuário: {this.props.user?.name || ""}
				</Typography>
				<UserEditionRouter userId={this.getIdFromUrl(this.props)}/>
				</Box>
			</ConditionalLoading>
		)
	}
}

const mapStateToProps = state => {
	const reducer = state.AdminUserReducer
	return {
		status: reducer.status,
		user: reducer.user,
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({
	doRequestAdminUser,
	adminUserReset,
}, dispatch)

export const UsersEdition = withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))
