"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GraphLeftAxis = void 0;

var formatDecimal = function formatDecimal(numberString, digits) {
  return numberString[numberString.length - digits] !== '0' ? ",".concat(numberString[numberString.length - digits]) : '';
};

var formatString = function formatString(numberString, digits) {
  return parseInt(numberString.slice(0, -digits)) > 1;
};

var GraphLeftAxis = function GraphLeftAxis(value) {
  var numberString = "".concat(value);
  if (numberString.length < 4) return value;

  if (numberString.length < 7) {
    var _decimal = formatDecimal(numberString, 3);

    return "".concat(numberString.slice(0, -3)).concat(_decimal, " m");
  }

  if (numberString.length < 10) {
    var _decimal2 = formatDecimal(numberString, 6);

    var millionString = formatString(numberString, 6) ? 'Ms' : 'M';
    return "".concat(numberString.slice(0, -6)).concat(_decimal2, " ").concat(millionString);
  }

  var decimal = formatDecimal(numberString, 9);
  var billionString = formatString(numberString, 9) ? 'Bs' : 'B';
  return "".concat(numberString.slice(0, -9)).concat(decimal, " ").concat(billionString);
};

exports.GraphLeftAxis = GraphLeftAxis;