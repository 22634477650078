import clone from "lodash/clone";

const planningState = {
	name: "",
	email: "",
	phone: "",
	bornAt: undefined,
	dependents: 0,
	academicalFormation: "",
	workRegime: "",
	maritalStatus: "",
	maritalRegime: "",
	city: "",
	UF: "",
	neighborhood: "",
	number: "",
	cep: "",
	complement: "",
	emergencyName: "",
	emergencyEmail: "",
	emergencyPhone: "",

	//expenses
	incomeItems: [],
	foodItems: [],
	housingItems: [],
	clothsItems: [],
	transportItems: [],
	personalCareItems: [],
	healthItems: [],
	educationItems: [],
	entertainmentItems: [],
	smokeItems: [],
	personalServicesItems: [],
	miscellaneousItems: [],
	otherExpensesItems: [],
	assetsIncreaseItems: [],
	liabilitiesDecreasingItems: [],
	recommendedPortfolioAssets: [],

};

export const initialState = {
	originalPlanning: clone(planningState),
	editablePlanning: clone(planningState),
	report: {
		status: "",
		data: {},
	},
	uiFixtures: {
		form: "none",
		formName: "",
		formPersonalUse: true,
		formAmount: 0,
	},

	user: undefined,
	//personal data
	name: "",
	bornAt: undefined,
	age: undefined,
	civilState: "",
	children: "",
	inssContribution: "",
	actualIncome: "",
	actualExpense: "",

	// Assets
	totalAssets: 0,
	liquidEquity: 0,

	totalFinancialInvestments: 0,
	totalRealState: 0,
	totalMovableAssets: 0,
	totalRights: 0,
	totalObligations: 0,

	financialInvestmentsItems: [],
	realStateItems: [],
	movableAssetsItems: [],
	rightsItems: [],
	obligationsItems: [],

	// Retirement
	expectedInterest: "",
	retirementAge: "",
	lifeExpectancy: "",
	retirementPlannedIncome: "",
	savingCapacity: 1,

	// Expenses

	expenses: {
		food: { value: 0 },
		housing: { value: 0 },
		cloths: { value: 0 },
		transport: { value: 0 },
		personalCare: { value: 0 },
		health: { value: 0 },
		education: { value: 0 },
		entertainment: { value: 0 },
		smoke: { value: 0 },
		personalServices: { value: 0 },
		miscellaneous: { value: 0 },
		otherExpenses: { value: 0 },
		assetsIncrease: { value: 0 },
		liabilitiesDecreasing: { value: 0 },
		totalExpenses: 0,
	},
	//Risk profile
	capacityInvestmentTime: "",
	capacityDependentsNumber: "",
	capacityEducation: "",
	capacityJob: "",
	capacityIncome: "",
	toleranceInvestmentHorizon: "",
	toleranceMarketPlungeDecision: "",
	tolerancePredictableInvestment: "",
	toleranceEqualResultInvestment: "",
	toleranceProfile: "",
	toleranceLiquidity: "",

	//ui state
	saveNeeded: false,
	changedAssets: false,
	fieldsChanged: {},

	isSavingPlanning: false,
	savingStatus: "",
	loadStatus: "none",
	INSSReceivingTimeSeriesValidation: {
		state: "empty",
		series: [],
	},

	//INSS Tab
	INSSReceivingDate: undefined,
	INSSReceivingAge: 0,
	INSSReceivingMinimumIncomeConsidered: 0,
	INSSReceivingIncome: 0,
	INSSReceivingTimeSeries: "",
	INSSFileProcessed: false,
};
