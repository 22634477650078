import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const loaderStyle = {
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
};

const resolveState = (state, children) => {
  return {
    none: () => "",
    failure: () => "Erro na request",
    loading: () => (
      <div style={loaderStyle}>
        <CircularProgress size={100} />
      </div>
    ),
    success: () => children,
  }[state]();
};

const ConditionalLoading = (props) => {
  return resolveState(props.state, props.children);
};

export { ConditionalLoading };
