import { requestActionCreator } from '../../../../../../core/sharedMethods/requestActionCreator.js'
import { requestAdminUser } from '../services/adminUser.js'

export const [
	adminUserLoadRequested,
	adminUserLoadSuccess,
	adminUserLoadFailure,
	adminUserReset ] = requestActionCreator('admin/user', 'load')


export function doRequestAdminUser(id) {
	return function (dispatch, getState, api) {
		dispatch(adminUserLoadRequested())
		requestAdminUser(api)(id)
		.then(({data, status})  => {

			//todo: check request status to trigger error
			dispatch(adminUserLoadSuccess( data ))
		}).catch(err=>{
			//todo: show error notification
			dispatch(adminUserLoadFailure())
		})
	}
}
