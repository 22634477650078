import React, { Component } from "react";
import { Route, Switch } from 'react-router-dom';
import { AppRoutes as AppRoutes } from "../core/routes.js";

export class OpenRouter extends Component {
  render() {
    return (
      <Switch>
        <Route path={AppRoutes.privacyPolicy}>
          <div>lol</div>
        </Route>
        <Route path={AppRoutes.usageTerms}>
          <div>lol</div>
        </Route>
      </Switch>
    );
  }
}

