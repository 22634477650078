import IconButton from '@material-ui/core/IconButton'
import { Edit, Ballot } from '@material-ui/icons';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

const UserTable =  function(props) {
	const classes = useStyles();
	let history = useHistory();

	function handleClick(id) {
		history.push(`/usuarios/${id}/diagnostic`);
	}

	function handleReportPreviewClick(id) {
		history.push(`/usuarios/${id}/diagnostic/preview`);
	}

	return (
	<TableContainer component={Paper}>
		<Table className={classes.table} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell>Nome</TableCell>
					<TableCell align="right">E-mail</TableCell>
					<TableCell align="right"></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.users.map((row, i) => (
				<TableRow key={`${row.name}-${i}`}>
					<TableCell component="th" scope="row">
						{row.name}
					</TableCell>
					<TableCell align="right">{row.email}</TableCell>
					<TableCell align="right">
						<IconButton aria-label="expand row" size="small" onClick={()=>{ handleClick(row._id)}}>
							{ <Edit /> }
						</IconButton>
						<IconButton aria-label="expand row" size="small" onClick={()=>{ handleReportPreviewClick(row._id)}}>
							{ <Ballot /> }
						</IconButton>
					</TableCell>
				</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>
	);
}

export {
	UserTable
}

