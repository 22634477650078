import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { CustomSingleCheckbox } from 'ui-lib'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import { KeyboardDatePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import { filterUndefined } from '../filterNullORUndefined'

import React, { Component } from 'react'
import { NumberFormatCustom } from '../../../../../../../../../../core/sharedMethods/NumberFormat'
import { TimeSeriesTable } from './TimeSeriesTable.js'

export class INSSTab extends Component {
	render () {
		const {
			updateField, form,
		} = this.props
		console.log(form)
		console.log(form.INSSFileProcessed)
		return (<Box pb={4} pt={4}>
			<Box pb={2} fontSize={20}>
				Cálculo de previdência - admin
			</Box>
			<form
				noValidate autoComplete="off">
				<Grid container direction={'row'} spacing={5}>

					<Grid item xs={3}>
						<Grid container direction={'column'} spacing={4}>
							<Grid item xs={12}>
								<KeyboardDatePicker
									fullWidth
									disableToolbar
									variant="inline"
									margin="normal"
									format="dd/MM/yyyy"
									id="date-picker-inline"
									label="Data de recebimento da previdência"
									value={form.INSSReceivingDate || null}
									onChange={(date) => {
										updateField({
											field: 'INSSReceivingDate', data: date,
										})
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}/>
							</Grid>
							<Grid item xs={12}>
								<TextField id="standard-adornment-amount"
													 value={form.INSSReceivingMinimumIncomeConsidered || ''}
													 onChange={(e) => {
														 updateField({
															 field: 'INSSReceivingMinimumIncomeConsidered', data: Number(e.target.value),
														 })
													 }}
													 fullWidth label="Salário mínimo considerado"
													 InputProps={{
														 inputComponent: NumberFormatCustom, startAdornment: <InputAdornment
															 position="start">R$</InputAdornment>,
													 }}/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									value={form.INSSAge || ''}
									onChange={(e) => {
										updateField({
											field: 'INSSAge', data: Number(e.target.value),
										})
									}}
									id="standard-basic" fullWidth
									label="Idade de recebimento do previdência"/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									value={filterUndefined(form.INSSProjectedIncome)}
									onChange={(e) => {
										updateField({
											field: 'INSSProjectedIncome', data: Number(e.target.value),
										})
									}}
									id="standard-adornment-amount" fullWidth
									label="Renda recebida do previdência"
									InputProps={{
										inputComponent: NumberFormatCustom, startAdornment: <InputAdornment
											position="start">R$</InputAdornment>,
									}}/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									label="Método de cálculo do benefício"
									fullWidth
									type="text"
									value={filterUndefined(form.INSSCalculationStrategy)}
									onChange={(e) => {
										updateField({
											field: 'INSSCalculationStrategy', data: e.target.value,
										})
									}}
								/>
							</Grid>
							<Box mb={3} width={'80%'}>
								<CustomSingleCheckbox
									value={form.INSSFileProcessed || false}
									updateField={updateField}
									fieldName={'INSSFileProcessed'}
									label="Dados do arquivo já processados?"
								/>
							</Box>
						</Grid>
					</Grid>
					<Grid item xs={4}>
						<TextField
							value={form.INSSReceivingTimeSeries || ''}
							multiline
							rows={18}
							onChange={(e) => {
								updateField({
									field: 'INSSReceivingTimeSeries', data: e.target.value,
								})
							}}
							id="standard-basic" fullWidth label="Série de dados Gráfico"/>
					</Grid>
					<Grid item xs={4}>
						<TimeSeriesTable data={form.INSSReceivingTimeSeries}/>
					</Grid>
				</Grid>
			</form>
		</Box>)
	}
}

INSSTab.propTypes = {
	form: PropTypes.shape({
		INSSReceivingDate: PropTypes.string, INSSReceivingAge: PropTypes.number,
		INSSReceivingMinimumIncomeConsidered: PropTypes.number, INSSReceivingIncome: PropTypes.number,
		INSSReceivingTimeSeries: PropTypes.string, INSSReceivingTimeSeriesValidation: PropTypes.object,
	}), updateField: PropTypes.func.isRequired,
}


