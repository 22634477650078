import { createReducer } from '@reduxjs/toolkit'
import * as ac from './actions.js'

const initialState = {
	status: 'none',
	reportData: {}
}

const AdminUserReportPreviewReducer = createReducer(initialState, {
	[ac.adminUsersReportPreviewLoadRequested]: (state) => {
		state.status = "loading"
		return state
	},
	[ac.adminUsersReportPreviewLoadSuccess]: (state, action) => {
		state.status = "success"
		state.reportData = action.payload
		return state
	},
	[ac.adminUsersReportPreviewReset]: (state) => {
		state.status = "none"
		state.reportData = {}
		return state
	},
	[ac.adminUsersReportPreviewLoadFailure]: (state) => {
		state.status = "failure"
		state.reportData = {}
		return state
	},
})

export { AdminUserReportPreviewReducer }
