"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useD3 = void 0;

var _react = _interopRequireDefault(require("react"));

var d3 = _interopRequireWildcard(require("d3"));

var useD3 = function useD3(renderChartFn, dependencies) {
  var ref = _react["default"].useRef();

  _react["default"].useEffect(function () {
    renderChartFn(d3.select(ref.current));
    return function () {};
  }, dependencies);

  return ref;
};

exports.useD3 = useD3;