import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, red } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear.js';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'absolute',
		bottom: 30,
		right: 30
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	buttonSuccess: {
		backgroundColor: green[500],
		'&:hover': {
			backgroundColor: green[700],
		},
	},
	buttonFailure: {
		backgroundColor: red[500],
		'&:hover': {
			backgroundColor: red[700],
		},
	},
	fabProgress: {
		color: green[500],
		position: 'absolute',
		top: -6,
		left: -6,
		zIndex: 1,
	},
}));

export const SavingButton = function (props) {
	const classes = useStyles();
	const loading = props.isSavingPlanning
	const status = props.savingStatus
	const timer = React.useRef();

	const buttonClassname = clsx({
		[classes.buttonSuccess]: status ==="success",
		[classes.buttonFailure]: status ==="failure",
	});

	React.useEffect(() => {
		const localTimer = timer.current
		return () => {
			clearTimeout(localTimer);
		};
	}, []);

	const handleButtonClick = () => {
		if (!loading) {
			props.doRequestPlanningSave()
		}
	};

	return (
	<div className={classes.root}>
		<div className={classes.wrapper}>
			<Fab
			aria-label="save"
			color="primary"
			className={buttonClassname}
			onClick={handleButtonClick}
			>
				{status ?
					status ==="success"? <CheckIcon /> : <ClearIcon/>
				: <SaveIcon />}
			</Fab>
			{loading && <CircularProgress size={68} className={classes.fabProgress} />}
		</div>
	</div>
	);
}
