import { SuccessNotification, ErrorNotification } from '../../../../../../../../core/sharedMethods/notifications.js'
import { enqueueSnackbar } from '../../../../../../../../genericPages/notifications/state/actions'
import { requestSavePersonalData, requestGetPersonalData } from '../services/services.js'
import { createAction, requestActionCreator } from '../../../../../../../../core/sharedMethods/requestActionCreator.js'

export const [
	adminUserPlanningLoadRequested,
	adminUserPlanningLoadSuccess,
	adminUserPlanningLoadFailure,
	adminUserPlanningReset ] = requestActionCreator('admin/user/planning/', 'load')

export function doRequestPlanningLoad(userId) {
	return function (dispatch, getState, api) {
		dispatch(adminUserPlanningLoadRequested())
		requestGetPersonalData(api)(userId)
		.then(({data, status })  => {
			dispatch(adminUserPlanningLoadSuccess(data))
		})
		.catch(e => {
			dispatch(adminUserPlanningLoadFailure())
			dispatch(enqueueSnackbar(ErrorNotification("Não foi possível carregar seu planejamento. Recarregue a página.")))
		})
	}
}

export const updateField = createAction('admin/user/planning/updateField', function prepare({field, data}){
	return {
		payload: {
			field,
			data
		}
	}
})

export const updateExpenseField = createAction('admin/user/planning/updateExpenseField')
export const updateINSSTimeSeries = createAction('admin/user/planning/updateINSSTimeSeries')

export const [
	adminUserPlanningSaveRequested,
	adminUserPlanningSaveSuccess,
	adminUserPlanningSaveFailure,] = requestActionCreator('admin/user/planning/', 'save')


export function doRequestPlanningSave() {
	return function (dispatch, getState, api) {
		const state = getState()
		const reducer = state.AdminUserPlanningReducer
		const user = state.AdminUserReducer.user

		const { originalPlanning, editablePlanning } = reducer;

		dispatch(adminUserPlanningSaveRequested())
		requestSavePersonalData(api)(user._id, editablePlanning)
		.then(({data, status })  => {
			dispatch(adminUserPlanningSaveSuccess())
			dispatch(enqueueSnackbar(SuccessNotification("Seu planejamento foi salvo com sucesso.")))
			setTimeout(()=>{
				dispatch(AdminUserPlanningSavingButtonReset())
			}, 500)
		})
		.catch(e => {
			dispatch(adminUserPlanningSaveFailure())
			dispatch(enqueueSnackbar(ErrorNotification("Não foi possível salvar seu planejamento. Tente novamente.")))
			setTimeout(()=>{
				dispatch(AdminUserPlanningSavingButtonReset())
			}, 500)
		})
	}
}



export const setAssetFormType = createAction('assetForm/setAssetFormType', function prepare(type){
	const types = ['none', 'realState', 'movableAssets', 'financialInvestments', 'rights', 'obligations']
	if(types.indexOf(type) === -1){
		type = 'none'
	}
	return {
		payload: {
			type
		}
	}
})

export const addAssetEntry = createAction('assetForm/addAssetEntry', function prepare({ name, key, personalUse, value}){
	return {
		payload: {
			name,
			key,
			personalUse,
			value
		}
	}
})

export const eAdminUserPlanningLoadUser = createAction('admin/user/loadFromList')
export const AdminUserPlanningSavingButtonReset = createAction('admin/user/planning/savingbutton/reset')

export const removeAssetEntry = createAction('assetForm/removeAssetEntry', function prepare({ id, key }){
	return {
		payload: {
			id,
			key
		}
	}
})



