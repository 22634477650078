import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { SavingButton } from './components/savingButton.js'
import { INSSTab } from './components/Tabs/inss/inss.js'
import {
	addAssetEntry, doRequestPlanningLoad, doRequestPlanningSave, removeAssetEntry,
	setAssetFormType, updateExpenseField, updateField, updateINSSTimeSeries,
	adminUserPlanningReset,
} from './state/actions.js'
import {mapStateToProps} from "./state/mapStateToProps";

function TabPanel (props) {
	const {
		children,
		value,
		index,
		...other
	} = props

	return (<div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	>
		{value === index && (<Box p={3}>
			<Typography component={'div'}>{children}</Typography>
		</Box>)}
	</div>)
}

class Index extends Component {
	constructor (props) {
		super(props)
		this.state = {
			selectedTab: 5,
		}
		this.handleTabSelection = this.handleTabSelection.bind(this)
	}

	getIdFromUrl(props) {
		const arrayPath = props.location.pathname.split("/")
		return arrayPath[2]
	}

	handleTabSelection (event, newValue) {
		this.setState({
			selectedTab: newValue,
		})
	}

	componentDidMount () {
		if (this.props.loadStatus === 'none') {
			this.props.doRequestPlanningLoad(this.getIdFromUrl(this.props))
		}
	}

	componentWillUnmount () {
		this.props.adminUserPlanningReset()
	}

	render () {
		const { selectedTab } = this.state
		const {
			saveNeeded,
			isSavingPlanning,
			savingStatus,
			doRequestPlanningSave,
		} = this.props
		return (<React.Fragment>
			{saveNeeded &&
			<SavingButton payload={{}} isSavingPlanning={isSavingPlanning}
										savingStatus={savingStatus}
										doRequestPlanningSave={doRequestPlanningSave}/>}
			<AppBar position="static">
				<Tabs value={selectedTab}
							onChange={this.handleTabSelection}
							indicatorColor="primary"
							variant="fullWidth"
							aria-label="simple tabs example">
					{/*<Tab label="Dados pessoais"/>*/}
					{/*<Tab label="Bens e direitos"/>*/}
					{/*<Tab label="Aposentadoria"/>*/}
					{/*<Tab label="Despesas"/>*/}
					{/*<Tab label="Perfil de risco"/>*/}
					<Tab label="INSS"/>
				</Tabs>
			</AppBar>
			{/*<TabPanel value={selectedTab} index={0}>*/}
			{/*	<Personal form={this.props.personal}*/}
			{/*						updateField={this.props.updateField}/>*/}
			{/*</TabPanel>*/}
			{/*<TabPanel value={selectedTab} index={1}>*/}
			{/*	<Assets*/}
			{/*		removeAssetEntry={this.props.removeAssetEntry}*/}
			{/*		addAssetEntry={this.props.addAssetEntry}*/}
			{/*		equity={this.props.equity}*/}
			{/*		setAssetFormType={this.props.setAssetFormType}*/}
			{/*		assetForm={this.props.assetForm}*/}
			{/*		updateField={this.props.updateField}/>*/}
			{/*</TabPanel>*/}
			{/*<TabPanel value={selectedTab} index={2}>*/}
			{/*	<Retirement*/}
			{/*		form={this.props.retirement}*/}
			{/*		updateField={this.props.updateField}/>*/}
			{/*</TabPanel>*/}
			{/*<TabPanel value={selectedTab} index={3}>*/}
			{/*	<Expenses expenses={this.props.expenses}*/}
			{/*						updateExpenseField={this.props.updateExpenseField}/>*/}
			{/*</TabPanel>*/}
			{/*<TabPanel value={selectedTab} index={4}>*/}
			{/*	<Suitability form={this.props.suitability}*/}
			{/*							 updateField={this.props.updateField}/>*/}
			{/*</TabPanel>*/}
			<TabPanel value={selectedTab} index={5}>
				<INSSTab form={this.props.INSS}
								 updateField={this.props.updateField}
								 updateINSSTimeSeries={this.props.updateINSSTimeSeries}/>
			</TabPanel>
		</React.Fragment>)
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({
	updateField,
	setAssetFormType,
	addAssetEntry,
	removeAssetEntry,
	doRequestPlanningSave,
	doRequestPlanningLoad,
	updateExpenseField,
	updateINSSTimeSeries,
	adminUserPlanningReset,
}, dispatch)

const UserPlanning = withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))

export { UserPlanning }
