import { createReducer } from '@reduxjs/toolkit'
import { adminUsersListLoadRequested, adminUsersListLoadFailure, adminUsersListReset, adminUsersListLoadSuccess } from './actions.js'

const initialState = {
	status: 'none',
	list: []
}

const AdminUserListReducer = createReducer(initialState, {
	[adminUsersListLoadRequested]: (state) => {
		state.status = "loading"
		return state
	},
	[adminUsersListLoadSuccess]: (state, action) => {
		state.status = "success"
		state.list = action.payload
		return state
	},
	[adminUsersListReset]: (state) => {
		state.status = "none"
		state.list = []
		return state
	},
	[adminUsersListLoadFailure]: (state) => {
		state.status = "failure"
		state.list = []
		return state
	},
})

export { AdminUserListReducer }
