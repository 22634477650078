import React from 'react'
import {
  Switch
}            from 'react-router-dom'
import { UserRouter } from "./userRouter"


export function AdminRouter() {
  return (<Switch>
    <UserRouter/>
  </Switch>)
}



