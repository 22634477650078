
export const rootAuthenticatedRoute = () => {
  return '/usuarios';
};


const protectedRoutes = {
  root: "/",
  home: '/dashboard',
  users: '/usuarios',
  userDiagnostic: '/usuarios/:id/diagnostic',
  userDiagnosticPreview: '/usuarios/:id/diagnostic/preview',
  userCreate: '/usuarios/novo',
}

const unauthorizedOnlyRoutes= {
  login:  "/login" ,
  forgotPassword:  "/esqueci-a-senha" ,
  passwordRecover:  "/recuperar" ,
  emailConfirmation:  "/confirmacao" ,
}

const openRoutes = {
  privacyPolicy: '/politica-de-privacidade',
  usageTerms: '/termos-de-uso',
}

export const AppRoutes = {
  ...protectedRoutes,
  ...unauthorizedOnlyRoutes,
  ...openRoutes,
};

export const ProtectedRoutes = Object.values(protectedRoutes)
export const UnauthorizedOnlyRoutes = Object.values(unauthorizedOnlyRoutes)
export const OpenRoutes = Object.values(openRoutes)