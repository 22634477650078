import { requestLogin } from "../../services/auth";
import LocalStorage from "../../../../core/localstorage";
import { enqueueErrorSnackbar } from "../../../notifications/state/actions";
import { requestActionCreatorAsObject } from "../../../../core/sharedMethods/requestActionCreator";

const loginActions = requestActionCreatorAsObject("user", "login");

export function doRequestLogin(login, password, redirect) {
  return function (dispatch, getState, api) {
    dispatch(loginActions.Request());
    requestLogin(api)(login, password)
      .then(({ data, status }) => {
        if(status === 200){
          LocalStorage.setAuthToken(data.accessToken);
          dispatch(loginActions.Success())
          redirect()
        } else {
          dispatch(loginActions.Failure());
          if (status === 409) {
            dispatch(
              enqueueErrorSnackbar(
                "Muitas tentativas de login falharam conta bloqueada temporáriamente."
              )
            );
          } else {
            dispatch(enqueueErrorSnackbar("Credenciais inválidas."));
          }
        }
      })
      .catch(() => {
        dispatch(loginActions.Failure());
        dispatch(
          enqueueErrorSnackbar(
            "Ocorreu um erro ao logar, tente novamente mais tarde"
          )
        );
      });
  };
}

export { loginActions };
