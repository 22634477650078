import LocalStorage from '../../../core/localstorage'

export const requestPasswordChange = (api) => (currentPassword, newPassword, passwordConfirmation , id ) => {
	const config = { headers: {Authorization: `Bearer ${LocalStorage.getToken()}`}}
	const route = api.routes.changePassword.replace(':id', id)
	return api.client.post(route, {
		currentPassword,
		newPassword,
		passwordConfirmation
	}, config)
}
