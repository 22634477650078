import axios from 'axios'
import routes from './backendRoutes'
import appConfig from '../config'
import LocalStorage from '../localstorage';

const axiosClient = axios.create({
	baseURL: appConfig.apiUrl,
	validateStatus: function (status) {
		return status >= 200 && status < 500; // default
	},
})

axiosClient.interceptors.request.use(function(config) {
	const token = LocalStorage.getAuthToken();
	config.headers.Authorization = `Bearer ${token}`
	if(appConfig.env === 'development'){
		config.headers['app-origin'] = 'admin'
	}

	return config;
});

const Api = {
	client: axiosClient,
	routes: routes
}

export default Api
