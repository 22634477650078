export default {
  login: '/api/v2/users/login',
  me: '/api/v2/users/me',
  changePassword: '/api/v1/userList/:id/changepassword',
  updateProfile: "/api/v2/users/updateProfile",

  clientsList: '/api/v2/clients',
  getClientsDiagnostic: '/api/v2/clients/:id/diagnostic',
  postClientsDiagnostic: '/api/v2/clients/:id/diagnostic',

  clientsDetails: '/api/v2/clients/:id',
  clientCreate: '/api/v2/clients',
};
