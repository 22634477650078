import Typography from '@material-ui/core/Typography'
import React, {Component} from 'react'
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import { doRequestAdminUserReportPreview, adminUsersReportPreviewReset } from './state/actions.js'
import { eAdminUserPlanningLoadUser } from '../userPlanning/state/actions.js'

class Index extends Component {
	constructor(props) {
		super(props)
		this.loadUserToPlanning = this.loadUserToPlanning.bind(this)
	}

	componentDidMount() {
		if(this.props.reportPreview.status === 'none'){
			this.props.doRequestAdminUserReportPreview()
		}
	}

	componentWillUnmount() {
		this.props.adminUsersReportPreviewReset()
	}

	loadUserToPlanning(user){
		this.props.eAdminUserPlanningLoadUser(user)
	}

	render() {
		return (
		<div style={{width: "calc( 100vw - 260px)", padding:20, display: 'flex', flexDirection: 'column'}}>
				<Typography style={{padding: 20}} variant="h6" gutterBottom>
					Preview de relatório
				</Typography>
		</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		reportPreview: state.AdminUserPreviewReducer,
		user: state.AdminUserReducer.user,
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({
	doRequestAdminUserReportPreview,
	adminUsersReportPreviewReset,
	eAdminUserPlanningLoadUser
}, dispatch)

export const UsersReportPreview = connect(mapStateToProps, mapDispatchToProps)(Index)
