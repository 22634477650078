

export const requestLogin = (api) => (email, password) => {
	return api.client.post(api.routes.login, {
		email,
		password,
	})
}

export const requestMe = (api) => (token) => {
	const config = { headers: {Authorization: `Bearer ${token}`}}
	return api.client.get(api.routes.me,  config)
}
