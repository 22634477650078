import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import React from "react"
import config from "./core/config"
import api from "./core/api/index"
import ReactDOM from "react-dom"
import "index.css"
import RootRouterV2 from "RootRouterV2"
import { Provider } from "react-redux"
import rootReducer from "./core/rootReducer"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import ReduxThunk from "redux-thunk"
import theme from "core/theme.js"
import {
  connectRouter, routerMiddleware, ConnectedRouter,
} from "connected-react-router"
import {
  ThemeProvider, StylesProvider, createGenerateClassName,
} from "@material-ui/core/styles"
import { createBrowserHistory } from "history"
import { SnackbarProvider } from "notistack"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/react"

if (config.sentry) {
  Sentry.init({
    dsn: config.sentry,
  })
}
serviceWorker.unregister()

const generateClassName = createGenerateClassName({
  seed: "pfp",
})

const history = createBrowserHistory()

const reducer = {
  ...rootReducer,
  router: connectRouter(history),
}

const store = configureStore({
  reducer: reducer,
  middleware: [
    ReduxThunk.withExtraArgument(api),
    ...getDefaultMiddleware(),
    routerMiddleware(history)],
  devTools: true, // preloadedState: loadState()
})

const root = document.getElementById("root")

ReactDOM.render(<Provider store={store}>
  <ConnectedRouter history={history}>
    <ThemeProvider theme={theme}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider maxSnack={3}>
            <RootRouterV2 />
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  </ConnectedRouter>
</Provider>, root)

