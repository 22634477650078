import { createAction } from '@reduxjs/toolkit'

const checkInputs =  (subject, op) =>{
	if(typeof subject !== "string"|| typeof op !== "string" || !op || !subject){
		throw new Error("Missing parameter on requestActionsCreator")
	}
}

const requestActionCreator = (subject, op ) => {
	checkInputs(subject, op)
	return [
		createAction(`${subject}${op}Request`),
		createAction(`${subject}${op}Success`),
		createAction(`${subject}${op}Failure`),
		createAction(`${subject}Reset`)
	]
}

const requestActionCreatorAsObject = (subject, op ) => {
	checkInputs(subject, op)
	return {
		'Request' : createAction(`${subject}/${op}/Request`),
		'Success' : createAction(`${subject}/${op}/Success`),
		'Failure' : createAction(`${subject}/${op}/Failure`),
		'Reset' : 	createAction(`${subject}/Reset`)
	}
}

export {
	createAction,
	requestActionCreator,
	requestActionCreatorAsObject
}
