"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locale = void 0;
var locale = {
  'decimal': ',',
  'thousands': '.',
  'grouping': [3],
  'currency': ['R$', '']
};
exports.locale = locale;