import LocalStorage from '../../../../../../../../core/localstorage'

export const requestSavePersonalData = (api) => (userId,  data) => {
	const config = { headers: {Authorization: `Bearer ${LocalStorage.getToken()}`}}
	const route = api.routes.postClientsDiagnostic.replace(':id', userId)
	return api.client.put(route, data, config)
}

export const requestGetPersonalData = (api) => (userId) => {
	const config = { headers: {Authorization: `Bearer ${LocalStorage.getToken()}`}}
	const route = api.routes.getClientsDiagnostic.replace(':id', userId)
	console.log(route, userId)
	return api.client.get(route, config)
}
