"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolveYAxisTranslate = void 0;
var lowerMax = 320;

var resolveYAxisTranslate = function resolveYAxisTranslate(positions) {
  positions[0] = 0;
  var lastIndex = positions.length - 1;

  if (positions.length === 4) {
    positions[lastIndex] = Math.min(positions[lastIndex], lowerMax);
    positions[lastIndex] = Math.max(positions[lastIndex], 75);
    positions[1] = Math.max(positions[1], positions[0] + 25);
    positions[1] = Math.min(positions[1], positions[lastIndex] - 50);
    positions[2] = Math.max(positions[2], positions[1] + 25);
    positions[2] = Math.min(positions[2], positions[lastIndex] - 25);
  } else if (positions.length === 3) {
    positions[lastIndex] = Math.min(positions[lastIndex], lowerMax);
    positions[lastIndex] = Math.max(positions[lastIndex], 50);
    positions[1] = Math.max(positions[1], positions[0] + 25);
    positions[1] = Math.min(positions[1], positions[lastIndex] - 25);
  } else if (positions.length === 2) {
    positions[lastIndex] = Math.min(positions[lastIndex], lowerMax);
    positions[lastIndex] = Math.max(positions[lastIndex], 25);
  }

  return positions;
};

exports.resolveYAxisTranslate = resolveYAxisTranslate;