import { AppRouter } from "./App/AppRouter";
import React, { Component } from "react";
import { Switch, withRouter } from "react-router-dom";
import LocalStorage from "./core/localstorage";
import {
	OpenRoutes,
	ProtectedRoutes,
	UnauthorizedOnlyRoutes,
} from "./core/routes";
import { AuthorizedRoute, UnauthorizedOnly } from "ui-lib";
import { AuthRouter } from "./App/AuthRouter";
import { OpenRouter } from "./App/OpenRouter";

class RootRouter extends Component {
	render() {
		const token = LocalStorage.getAuthToken();
		return (
			<Switch>
				<OpenRouter path={OpenRoutes} />
				<UnauthorizedOnly token={token} path={UnauthorizedOnlyRoutes}>
					<AuthRouter />
				</UnauthorizedOnly>
				<AuthorizedRoute token={token} path={ProtectedRoutes}>
					<AppRouter token={token} />
				</AuthorizedRoute>
			</Switch>
		);
	}
}

export default withRouter(RootRouter);
