import { createReducer } from '@reduxjs/toolkit'
import * as ac from './actions.js'
import isEqual from "lodash/isEqual";
import { clearForm, updateTotal, getItemsKey, sumExpenses, checkExpensesMutation, parseTimeSeries,
  computeDerivedFieldUpdate,} from './stateHelpers.js'
import { initialState } from './initialState.js'
import { v4 as uuid } from "uuid"

const AdminUserPlanningReducer = createReducer(initialState, {
  [ac.eAdminUserPlanningLoadUser]: (state, action) => {
    return { ...state, user: { ...action.payload}}
  },

  [ac.adminUserPlanningLoadRequested]: (state, action) => {
    let temp = { ...state}
    temp['loadStatus'] = 'loading'
    return temp
  },
  [ac.adminUserPlanningLoadSuccess]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loadStatus: 'success',
      originalPlanning: action.payload,
      editablePlanning: {...action.payload},
    }
  },
  [ac.adminUserPlanningLoadFailure]: (state, action) => {
    let temp = { ...state}
    temp['loadStatus'] = 'loading'
    return temp
  },
  [ac.updateExpenseField]: (state, { payload} ) => {
    state.expenses[payload.field] = { value: payload.value }
    state.expenses.totalExpenses = sumExpenses(state.expenses)
    state["saveNeeded"] = checkExpensesMutation(state)
    return state
  },
  [ac.updateField]: (state, action) => {
    const editablePlanning = state.editablePlanning;
    const {field, data} = action.payload;
    editablePlanning[field] = data;
    state.editablePlanning = computeDerivedFieldUpdate(editablePlanning, field, data);
    state["saveNeeded"] = !isEqual(
      state.originalPlanning,
      state.editablePlanning
    );
    return state;
  },
  [ac.setAssetFormType]: (state, action) => {
    let temp = { ...state}
    temp['form'] = action.payload.type
    temp = clearForm(temp)
    return temp
  },
  [ac.addAssetEntry]: (state, action ) => {
    let temp = { ...state}
    const { key, value, name, personalUse } = action.payload
    const itensKey = getItemsKey(key)
    temp[itensKey] = [ ...temp[itensKey], { value: Number(value), name, personalUse, id: uuid()} ]
    temp = clearForm(temp)
    temp = updateTotal(key, temp)
    temp["saveNeeded"] = true
    temp["changedAssets"] = true
    return temp
  },
  [ac.removeAssetEntry]: (state, action ) => {
    let temp = { ...state }
    const { key, id } = action.payload
    const itensKey = getItemsKey(key)
    temp[itensKey] = temp[itensKey].filter(it=> it.id !== id)
    temp = updateTotal(key, temp)
    temp["saveNeeded"] = true
    temp["changedAssets"] = true
    return temp
  },
  [ac.adminUserPlanningSaveRequested]: (state, action) => {
    return {...state, isSavingPlanning:true}
  },
  [ac.adminUserPlanningSaveFailure]: (state, action) => {
    return {...state, isSavingPlanning:false, savingStatus: 'failure'}
  },
  [ac.adminUserPlanningSaveSuccess]: (state, action) => {
    return { ...state,
      ...action.payload,
      originalPlanning: action.payload,
      saveNeeded: false,
      changedAssets: false,
      savingStatus: 'success',
      fieldsChanged: {},  }
  },
  [ac.adminUserPlanningReset]: (state, action) => {
    return { ...initialState}
  },
  [ac.AdminUserPlanningSavingButtonReset]: (state) => {
    return {...state, isSavingPlanning:false, savingStatus: '', saveNeeded: false,}
  },
  [ac.updateINSSTimeSeries]: (state, action) => {
    if(!action.payload.value){
      state.INSSReceivingTimeSeries = ""
      state.INSSReceivingTimeSeriesValidation = {
        state: "empty",
        series:[]
      }
      state.saveNeeded = true
      return state
    }

    try{
      state.INSSReceivingTimeSeries = action.payload.value
      state.INSSReceivingTimeSeriesValidation = parseTimeSeries(action.payload.value)
      state.saveNeeded = true
    } catch(e){
      state.INSSReceivingTimeSeriesValidation = {
        state: "error",
        series:[]
      }
    }

    return state
  },
})

export { AdminUserPlanningReducer }
