import { createReducer } from "@reduxjs/toolkit";
import { loginActions, updateField, resetState, logoff, meActions} from './state/actions'

const initialState = {

  formEmail: "",
  formPassword: "",
  loginRequestStatus: "none",
  meRequestStatus: "none",
  loggedIn: false,

};

const reducer = createReducer(initialState, {
  [updateField]: (state, action) => {
    const temp = { ...state };
    temp[action.payload.field] = action.payload.data;
    return temp;
  },
  [meActions.Request]: (state, action) => {
    state.meRequestStatus = 'loading';
    return state;
  },
  [meActions.Success]: (state, action) => {
    state.meRequestStatus = 'success';
    state.name = action.payload.name;
    state.email = action.payload.email;
    state.phone = action.payload.phone;
    state.roles = action.payload.roles;
    state.id = action.payload._id;
    state.loggedIn = true;
    return state;
  },
  [meActions.Failure]: (state, action) => {
    return { ...initialState };
  },


  //login
  [loginActions.Failure]: (state, action) => {
    state.formPassword = "";
    state.formEmail = "";
    state.loginRequestStatus = "none";
    return state;
  },

  [loginActions.Request]: (state, action) => {
    state.loginRequestStatus = "loading";
    return state;
  },
  [loginActions.Success]: (state, action) => {
    state.loginRequestStatus = 'success'
    state.loggedIn = true;
    state.formPassword = "";
    state.formConfirmation = "";
    state.formEmail = "";
    return state;
  },
  [logoff]: (state, action) => {
    return { ...initialState };
  },
});

export default reducer;
