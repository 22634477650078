import authReducer from "genericPages/login/authReducer";
import accountReducer from "pages/account/state/reducer";
import notificationsReducer from "genericPages/notifications/state/reducer";
import { AdminUserListReducer } from "pages/Admin/pages/user/userList/state/adminUserListReducer.js";
import { AdminUserReportPreviewReducer } from "pages/Admin/pages/user/userEdition/pages/userReportPreview/state/adminUserReportPreviewReducer.js";
import { AdminUserPlanningReducer } from "pages/Admin/pages/user/userEdition/pages/userPlanning/state/AdminUserPlanningReducer.js";
import { AdminUserReducer } from "pages/Admin/pages/user/userEdition/state/adminUserReducer.js";

//todo: normalize reducer key case
export default {
  auth: authReducer,
  account: accountReducer,
  notifications: notificationsReducer,
  AdminUserListReducer: AdminUserListReducer,
  AdminUserPlanningReducer: AdminUserPlanningReducer,
  AdminUserReducer: AdminUserReducer,
  AdminUserPreviewReducer: AdminUserReportPreviewReducer,
};
