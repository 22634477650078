export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const enqueueSnackbar = notification => {
	const key = notification.options && notification.options.key;

	return {
		type: ENQUEUE_SNACKBAR,
		notification: {
			...notification,
			key: key || new Date().getTime() + Math.random()
		}
	};
};

export const closeSnackbar = key => ({
	type: CLOSE_SNACKBAR,
	dismissAll: !key, // dismiss all if no key has been defined
	key
});

export const removeSnackbar = key => ({
	type: REMOVE_SNACKBAR,
	key
});

export const enqueueSuccessSnackbar = (message, options) => {
	return {
		type: ENQUEUE_SNACKBAR,
		notification: {
			message,
			key: new Date().getTime() + Math.random(),
			options: {
				variant: "Success",
				...options
			},
		},
	};
};

export const enqueueWarningSnackbar = (message, options) => {
	return {
		type: ENQUEUE_SNACKBAR,
		notification: {
			message,
			key: new Date().getTime() + Math.random(),
			options: {
				variant: "Warning",
				...options,
			},
		},
	};
};

export const enqueueErrorSnackbar = (message, options) => {
	return {
		type: ENQUEUE_SNACKBAR,
		notification: {
			message,
			key: new Date().getTime() + Math.random(),
			options: {
				variant: "Error",
				...options
			},
		},
	};
};
