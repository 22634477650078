"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTopBorder = exports.getRightBorder = exports.getAngleValue = void 0;

var getAngleValue = function getAngleValue(percentage) {
  return -90 + 9 / 5 * percentage;
};

exports.getAngleValue = getAngleValue;
var lateralBorder = 30;
var fullSize = 250;
var halfSize = fullSize / 2;

var getRightBorder = function getRightBorder(percentage) {
  if (percentage <= 50) {
    return 100;
  }

  var angle = getAngleValue(percentage);
  var radians = angle * (Math.PI / 180);
  var offset = Math.sin(radians) * 70;
  var border = 100 - offset;
  return border;
};

exports.getRightBorder = getRightBorder;

var getTopBorder = function getTopBorder(percentage) {
  var angle = getAngleValue(percentage);
  var radians = angle * (Math.PI / 180);
  return percentage >= 50 ? lateralBorder : 100 - Math.cos(radians) * 70;
};

exports.getTopBorder = getTopBorder;