import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { updateField, doRequestLogin } from "../state/actions";
import { SigninForm } from "ui-lib";
import { rootAuthenticatedRoute } from "../../../core/routes";

class SigninContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);
    this.redirectAfterLogin = this.redirectAfterLogin.bind(this);
  }

  onForgotPasswordClick() {

  }

  redirectAfterLogin() {
    const from = this.props.location.state?.from?.pathname || rootAuthenticatedRoute();
    this.props.history.push(from);
  }

  render() {
    const {
      formEmail,
      formPassword,
      loginRequestStatus,
      updateField,
      doRequestLogin,
    } = this.props;

    return (
      <SigninForm
        variant={'consultant'}
        ui={{ loginRequestStatus }}
        actions={{
          updateField,
          doRequestLogin,
          redirectAfterLogin: this.redirectAfterLogin,
          onForgotPasswordClick: this.onForgotPasswordClick,
        }}
        values={{ formPassword, formEmail }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateField,
      doRequestLogin,
    },
    dispatch
  );
};

const Signin = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SigninContainer)
);

export { Signin };
