import {pick} from 'lodash'

const allowedFields = {
  personal: [
    'name',
    'email',
    'phone',
    'bornAt',
    'dependents',
    'academicalFormation',
    'workRegime',
    'maritalStatus',
    'maritalRegime',
    'city',
    'UF',
    'neighborhood',
    'number',
    'cep',
    'complement',
    'actualIncome',
    'aliveParents',
    'children',
    'followsRGPS',
    'RPPSRate',
  ],
  equity: [
    'financialInvestmentsItems',
    'movableAssetsItems',
    'rightsItems',
    'obligationsItems',
    'realStateItems',
  ],
  fiscal: [
    'incomeItems',
    'alimony',
    'taxBreaks',
    'pensionContribution',
    'workRegime',
    'yearlyTaxableIncome',
    'deductibleMedicalExpense',
    'dependents',
    'useCustomAutonomousContribution',
    'autonomousContribution',
  ],
  retirement: [
    'expectedInterest',
    'retirementAge',
    'lifeExpectancy',
    'retirementPlannedIncome',
    'assetsIncreaseItems',
    'financialInvestmentsItems',
    'rightsItems',
    'movableAssetsItems',
    'realStateItems',
  ],
  assetForm: ['form', 'formName', 'formPersonalUse', 'formAmount'],
  portfolio: [
    'stocksPercentage',
    'internationalPercentage',
    'multiMarketPercentage',
    'preFixedPercentage',
    'postFixedPercentage',
    'inflationPercentage',
    'CDIProjection',
    'IBOVProjection',
    'dollarProjection',
    'actualSecurityReserve',
    'financialInvestmentsItems',
    'recommendedPortfolioAssets',
  ],
  INSS: [
    'INSSReceivingDate',
    'INSSAge',
    'INSSReceivingMinimumIncomeConsidered',
    'INSSProjectedIncome',
    'INSSReceivingTimeSeries',
    'INSSReceivingTimeSeriesValidation',
    'INSSCalculationStrategy',
    'INSSAnalysisIncomeProjectionValueToUse',
    'INSSAnalysisCustomMinimumIncome',
    'INSSFile',
    'INSSFileProcessed',
  ],

  suitability: [
    'capacityInvestmentTime',
    'capacityDependentsNumber',
    'capacityEducation',
    'capacityJob',
    'capacityIncome',
    'toleranceInvestmentHorizon',
    'toleranceMarketPlungeDecision',
    'tolerancePredictableInvestment',
    'toleranceEqualResultInvestment',
    'toleranceProfile',
    'toleranceLiquidity',
  ],
  expenses: [
    'foodItems',
    'housingItems',
    'clothsItems',
    'transportItems',
    'personalCareItems',
    'healthItems',
    'educationItems',
    'entertainmentItems',
    'smokeItems',
    'personalServicesItems',
    'miscellaneousItems',
    'otherExpensesItems',
    'assetsIncreaseItems',
    'liabilitiesDecreasingItems',
    'dependents',
  ],
  succession: [
    'heirloomTaxRate',
    'OABRate',
    'meationRate',
    'maritalRegime',
  ],
  extraDoubts: [
    'extraDoubts',
  ],
  risk: [
    'riskDeadline',
    'amountToCover',
  ]
}

export const mapStateToProps = (state) => {
  const reducer = state['AdminUserPlanningReducer']
  const editablePlanning = reducer.editablePlanning
  return {
    personal: pick(editablePlanning, allowedFields.personal),
    equity: pick(editablePlanning, allowedFields.equity),

    retirement: pick(editablePlanning, allowedFields.retirement),
    assetForm: pick(editablePlanning, allowedFields.assetForm),

    INSS: pick(editablePlanning, allowedFields.INSS),
    suitability: pick(editablePlanning, allowedFields.suitability),

    isSavingPlanning: editablePlanning.isSavingPlanning,
    savingStatus: editablePlanning.savingStatus,
    saveNeeded: reducer.saveNeeded,

    expenses: pick(editablePlanning, allowedFields.expenses),
    fiscal: {
      ...pick(editablePlanning, allowedFields.fiscal),
      educationExpenseItems: editablePlanning.educationItems,
      healthExpenseItems: editablePlanning.healthItems,
    },
    succession: pick(editablePlanning, allowedFields.succession),
    extraDoubts: pick(editablePlanning, allowedFields.extraDoubts),
    risk: pick(editablePlanning, allowedFields.risk),

    loadStatus: reducer.loadStatus,

    userId: state.auth.id,

    incomeItems: editablePlanning.incomeItems,

    ui: reducer.uiFixtures,
  }
}