import React from 'react'
import {
  Route,
  Switch
}            from 'react-router-dom'
import { UsersList } from './userList'
import { UsersEdition } from './userEdition'
import { AppRoutes as AppRoutes } from '../../../../core/routes.js';


export function UserRouter() {
  //root: /admin/usuarios
  return (<Switch>

    <Route path={AppRoutes.userDiagnostic}>
      <UsersEdition/>
    </Route>
    <Route path={AppRoutes.user}>
      <UsersList/>
    </Route>
  </Switch>)
}



