import { maritalStatusMeationMapping } from '../defs/personalFormOptions'

export const sumItems = (items)=> {
	return items.reduce((p,c)=> p + c.value, 0)
}

export const clearForm = (state) => {
	state['formName'] = ""
	state['formPersonalUse'] = true
	state['formAmount'] = 0
	return state
}

export const getTotalKey = key => {
	return `total${key[0].toUpperCase() + key.slice(1)}`
}

export const getItemsKey = key =>{
	return `${key}Items`
}

export const updateTotal = (key, state) =>{
	const totalKey = getTotalKey(key)
	const itemsKey = getItemsKey(key)

	if(state[itemsKey].length){
		state[totalKey] = state[itemsKey].reduce((p,c) => {
			return p + c.value
		}, 0)
		return state
	} else {
		state[totalKey] = 0
		return state
	}
}

export const checkFieldMutation = (state) => {
	if(state.changedAssets){
		return true
	} else {
		const keys = Object.keys(state.fieldsChanged)
		if(!keys.length){
			return false
		} else {
			return keys.some((k) =>{
				if(!state.originalPlanning[k] && state.fieldsChanged[k]===""){
					return false
				}
				return state.fieldsChanged[k] !== state.originalPlanning[k]
			})
		}
	}
}

const derivedFieldUpdateMapping = {
	'maritalRegime': (planning, data) => {
		const newMeationRateValue = maritalStatusMeationMapping[data] || 0
		if (newMeationRateValue !== undefined) {
			planning.meationRate = newMeationRateValue
		}
		return planning
	},
	'maritalStatus': (planning, data) => {
		if (data !== 'married') {
			planning.maritalRegime = undefined
			planning.meationRate = 0
		}
		return planning
	},
	'dependents': (planning, data) => {
		if (data === 0 || data === '0') {
			planning.educationItems = planning.educationItems.map(item => ({ ...item, alocation: 'self'}))
		}
		return planning
	},
}

export const computeDerivedFieldUpdate = (planning, field, data) => {
	const operation = derivedFieldUpdateMapping[field]
	if (!operation) {
		return planning
	} else {
		return operation(planning, data)
	}
}

export const sumExpenses = (expenses) => {
	return Object.keys(expenses).reduce((sum, currentKey) => {
		 if( currentKey === "totalExpenses" ){
		 	return sum
		 }
		 return sum + expenses[currentKey].value
	}, 0)
}

export const checkExpensesMutation = (state) => {
	if( !state.originalPlanning.expenses || state.expenses.total !== state.originalPlanning.expenses.total){
		return true
	} else {
		return JSON.stringify(state.expenses) !== JSON.stringify(state.originalPlanning.expenses)
	}
}

export const parseTimeSeries= (string) => {
	if (!string){
		return {
			state: "empty",
			series: []
		}
	}

	const series = []
	const lines = string.split('\n')
	lines.forEach(line =>{
		const values = line.split("\t")
		if (values.length !== 3){
			series.push({label: "invalid Line:", contribution: line, receiving: ""})
		} else {
			series.push({label: values[0], contribution: values[1], receiving: values[2] }	)
		}
	})

	return {
		state: "filled",
		series
	}
}

