import { createReducer } from '@reduxjs/toolkit'
import { adminUserLoadSuccess, adminUserLoadRequested, adminUserLoadFailure, adminUserReset } from './actions.js'

const initialState = {
	status: 'none',
	user: undefined
}

const AdminUserReducer = createReducer(initialState, {
	[adminUserLoadRequested]: (state) => {
		state.status = "loading"
		return state
	},
	[adminUserLoadSuccess]: (state, action) => {
		state.status = "success"
		state.user = action.payload
		return state
	},
	[adminUserReset]: (state) => {
		state.status = "none"
		state.user = {}
		return state
	},
	[adminUserLoadFailure]: (state) => {
		state.status = "failure"
		state.user = {}
		return state
	},
})

export { AdminUserReducer }
