import { requestMe } from "../../services/auth";
import LocalStorage from "../../../../core/localstorage";

import { requestActionCreatorAsObject } from "../../../../core/sharedMethods/requestActionCreator";

const meActions = requestActionCreatorAsObject("user", "me");

export function doRequestMe(history, token) {
  return function (dispatch, getState, api) {
    dispatch(meActions.Request());
    if (!token) {
      dispatch(meActions.Failure());
      history.push("/login");
      return;
    }
    requestMe(api)(token).then(({ data, status }) => {
      if (status >= 200 && status < 400) {
        dispatch(meActions.Success(data));
      } else {
        dispatch(meActions.Failure());
        LocalStorage.removeAuthToken();
        history.push("/login");
      }
    });
  };
}

export { meActions };
