"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Root = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var Root = _styledComponents["default"].div.withConfig({
  displayName: "Layout__Root",
  componentId: "sc-10ap54n-0"
})(["width:100%;background-color:white;display:flex;flex-direction:column;"]);

exports.Root = Root;