import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes as AppRoutes } from '../../../../../core/routes.js';
import { UserPlanning } from './pages/userPlanning';
import { UsersReportPreview } from './pages/userReportPreview';

export function UserEditionRouter(props) {
	//root: /admin/usuarios
	return (<Switch>
		<Route exact path={AppRoutes.userDiagnostic}>
			<UserPlanning {...props}/>
		</Route>
		<Route path={AppRoutes.userDiagnosticPreview}>
			<UsersReportPreview {...props}/>
		</Route>
	</Switch>)
}
