import {  requestActionCreator } from '../../../../../../core/sharedMethods/requestActionCreator.js'
import { requestAdminUserList } from '../services/adminUsersList.js'

export const [
	adminUsersListLoadRequested,
	adminUsersListLoadSuccess,
	adminUsersListLoadFailure,
	adminUsersListReset ] = requestActionCreator('admin/users/list/', 'load')


export function doRequestAdminUserList() {
	return function (dispatch, getState, api) {
		dispatch(adminUsersListLoadRequested())
		requestAdminUserList(api)()
		.then(({data, status})  => {

			//todo: check request status to trigger error
			dispatch(adminUsersListLoadSuccess( data ))
		}).catch(err=>{
			//todo: show error notification
			dispatch(adminUsersListLoadFailure())
		})
	}
}
