
const TOKEN_NAMESPACE = "monefica:admin:accessToken"
const REFRESH_TOKEN_NAMESPACE = "monefica:admin:refreshToken";

class localstorage {
	static setToken(value){
		console.warn('deprecated local storage function call')
		localStorage.setItem(TOKEN_NAMESPACE, value)
	}

	static getToken(){

		console.warn('deprecated local storage function call')
		return localStorage.getItem(TOKEN_NAMESPACE)
	}

	static removeToken(){
		console.warn('deprecated local storage function call')
		return localStorage.removeItem(TOKEN_NAMESPACE)
	}
	//

	static setAuthToken(value) {
		localStorage.setItem(TOKEN_NAMESPACE, value);
	}

	static setRefreshToken(value) {
		localStorage.setItem(REFRESH_TOKEN_NAMESPACE, value);
	}

	static getAuthToken() {
		return localStorage.getItem(TOKEN_NAMESPACE);
	}

	static removeAuthToken() {
		return localStorage.removeItem(TOKEN_NAMESPACE);
	}

	static getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_NAMESPACE);
	}

	static removeRefreshToken() {
		return localStorage.removeItem(REFRESH_TOKEN_NAMESPACE);
	}
}
export default localstorage