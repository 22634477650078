export const workRegimeOptions = [
  {
    label: "CLT",
    value: "clt",
  },
  {
    label: "Autônomo",
    value: "autonomous",
  },
  {
    label: "Estatutário",
    value: "statutory",
  },
  {
    label: "Desempregado",
    value: "Unemployed",
  },
];


export const followsRGPSOptions = [
  {
    label: "Sim",
    value: 'true',
  },
  {
    label: "Não",
    value: 'false',
  },
];

export const maritalStatusOptions = [
  {
    label: "Casado(a)",
    value: "married",
  },
  {
    label: "Solteiro(a)",
    value: "single",
  },
  {
    label: "Divorciado(a)",
    value: "divorced",
  },
  {
    label: "Viúvo(a)",
    value: "widower",
  },
];

export const aliveParentsOptions = [
  {
    label: "Nenhum",
    value: "Nenhum",
  },
  {
    label: "Um",
    value: "Um",
  },
  {
    label: "Dois",
    value: "Dois",
  },
];


export const maritalStatusMeationMapping = {
  "universalCommunion" : 50,
  "totalSegregation" : 0,
}

export const maritalRegimeOptions = [
  {
    label: "Compartilha parcial",
    value: "partialCommunion",
  },
  {
    label: "Comunhão universal",
    value: "universalCommunion",
  },
  {
    label: "Separação total de bens",
    value: "totalSegregation",
  },
];

export const academicalFormationOptions = [
  {
    label: "Ensino fundamental",
    value: "Fundamental",
  },
  {
    label: "Ensino médio",
    value: "Ensino medio",
  },
  {
    label: "Superior",
    value: "Superior",
  },
  {
    label: "Pós-graduação",
    value: "Pós-graduação",
  },
  {
    label: "Mestrado",
    value: "Mestrado",
  },
  {
    label: "Doutorado",
    value: "Doutorado",
  },
];

export const UFOptions = [
  { value: "RO", label: "RO" },
  { value: "AC", label: "AC" },
  { value: "AM", label: "AM" },
  { value: "RR", label: "RR" },
  { value: "PA", label: "PA" },
  { value: "AP", label: "AP" },
  { value: "TO", label: "TO" },
  { value: "MA", label: "MA" },
  { value: "PI", label: "PI" },
  { value: "CE", label: "CE" },
  { value: "RN", label: "RN" },
  { value: "PB", label: "PB" },
  { value: "PE", label: "PE" },
  { value: "AL", label: "AL" },
  { value: "SE", label: "SE" },
  { value: "BA", label: "BA" },
  { value: "MG", label: "MG" },
  { value: "ES", label: "ES" },
  { value: "RJ", label: "RJ" },
  { value: "SP", label: "SP" },
  { value: "PR", label: "PR" },
  { value: "SC", label: "SC" },
  { value: "RS", label: "RS" },
  { value: "MS", label: "MS" },
  { value: "MT", label: "MT" },
  { value: "GO", label: "GO" },
  { value: "DF", label: "DF" },
];
