import {  requestActionCreator } from '../../../../../../../../core/sharedMethods/requestActionCreator.js'
import { requestAdminPlanningReportPreview } from '../services/UserReportPreviewServices.js'

export const [
	adminUsersReportPreviewLoadRequested,
	adminUsersReportPreviewLoadSuccess,
	adminUsersReportPreviewLoadFailure,
	adminUsersReportPreviewReset ] = requestActionCreator('admin/users/report/preview/', 'load')


export function doRequestAdminUserReportPreview( planningId) {
	return function (dispatch, getState, api) {
		dispatch(adminUsersReportPreviewLoadRequested())
		requestAdminPlanningReportPreview(api)(planningId)
		.then(({data, status})  => {
			//todo: check request status to trigger error
			dispatch(adminUsersReportPreviewLoadSuccess( data ))
		}).catch(err=>{
			//todo: show error notification
			dispatch(adminUsersReportPreviewLoadFailure())
		})
	}
}
