import { createAction } from '@reduxjs/toolkit';
import LocalStorage from '../../../../core/localstorage';

export const updateField = createAction("login/updateField");
export const resetState = createAction("login/resetState");


export const logoff = createAction("login/logoff", function prepare(
  redirect,
  route
) {
  LocalStorage.removeAuthToken();
  redirect(route);
  return {
    payload: {},
  };
});

