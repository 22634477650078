import Typography from '@material-ui/core/Typography'
import React, {Component} from 'react'
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import { UserTable } from './components/UserTable.js'
import { doRequestAdminUserList, adminUsersListReset } from './state/actions.js'
import { eAdminUserPlanningLoadUser } from '../userEdition/pages/userPlanning/state/actions.js'
import { ConditionalLoading } from '../../../../../components/ConditionalLoading.js'

class Index extends Component {
	constructor(props) {
		super(props)
		this.loadUserToPlanning = this.loadUserToPlanning.bind(this)
	}

	componentDidMount() {
		if(this.props.userList.status === 'none'){
			this.props.doRequestAdminUserList()
		}
	}

	componentWillUnmount() {
		this.props.adminUsersListReset()
	}

	loadUserToPlanning(user){
		this.props.eAdminUserPlanningLoadUser(user)
	}

	render() {
		return (
		<div style={{width: "calc( 100vw - 260px)", padding:20, display: 'flex', flexDirection: 'column'}}>
			<ConditionalLoading state={this.props.userList.status}>
				<Typography style={{padding: 20}} variant="h6" gutterBottom>
					Planejamento de: {this.props.user?.name || ""}
				</Typography>
				<UserTable users={this.props.userList.list} loadUser={this.loadUserToPlanning}/>
			</ConditionalLoading>
		</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userList: state.AdminUserListReducer
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({
	doRequestAdminUserList,
	adminUsersListReset,
	eAdminUserPlanningLoadUser
}, dispatch)

export const UsersList = connect(mapStateToProps, mapDispatchToProps)(Index)
