import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {parseTimeSeries} from "./parseTimeSeries";

export class TimeSeriesTable extends React.Component {
  render() {
    const {state, series} = parseTimeSeries(this.props.data)

    return (
      <TableContainer component={Paper}>
        {state === "filled" ?
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">Ano</TableCell>
              <TableCell align="right">Contribuição</TableCell>
              <TableCell align="right">Recebimento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {series.map((row) => (
              <TableRow key={`${row.label}-${row.contribution}`}>
                <TableCell align="right">{row.label}</TableCell>
                <TableCell align="right">{Number(row.contribution.replace(',', '.')).toFixed(2)}</TableCell>
                <TableCell align="right">{Number(row.receiving.replace(',', '.')).toFixed(2)}</TableCell>
              </TableRow>))}
          </TableBody>
        </Table>:"Erro nos dados"}
      </TableContainer>
    );
  }
}
