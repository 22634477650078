"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Theme = void 0;

var _styles = require("@material-ui/core/styles");

var Theme = (0, _styles.createTheme)({
  palette: {
    primary: {
      main: '#4e4ba6'
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 0,
      desktop: 1200
    }
  }
});
exports.Theme = Theme;