"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTabs = void 0;

var _react = _interopRequireDefault(require("react"));

var _styles = require("@material-ui/core/styles");

var _Tabs = _interopRequireDefault(require("@material-ui/core/Tabs"));

var _variables = require("../../specs/variables");

var StyledTabs = (0, _styles.withStyles)({
  indicator: {
    backgroundColor: _variables.Colors.primary400
  }
})(_Tabs["default"]);
exports.StyledTabs = StyledTabs;