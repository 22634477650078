import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4e4ba6',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

export default theme
