import { createReducer } from '@reduxjs/toolkit'
import { updateField, changePasswordFailure, changePasswordRequested, changePasswordSuccess } from './actions'

const initialState = {
    currentPassword: 'testeTeste1',
    newPassword: 'testeTeste1',
    passwordConfirmation: 'testeTeste1',
    isRequestingPasswordChange: false
}

const reducer = createReducer(initialState, {
    [updateField]: (state, action) => {
     const temp = { ...state}
     temp[action.payload.field] = action.payload.data
      return temp
    },
    [changePasswordRequested]: (state, action) => {
        state.loginRequested = true
        return state
    },
    [changePasswordFailure]: (state, action) => {
        state.meRequested = true
        return state
    },
    [changePasswordSuccess]: (state, action) => {
        return state
    },
})

export default reducer
