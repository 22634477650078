"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Variables = exports.Colors = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var Colors = {
  lightBlue: '#c9dff2',
  mediumBlue: '#4B4EA0',
  darkBlue: '#3a3873',
  grayBlue: '#9fb0bf',
  grayDark: '#E4E4E4',
  gold: '#d9bc66',
  black: '#000',
  white: '#fff',
  red: '#D57272',
  primary900: '#24036a',
  primary800: '#360f79',
  primary700: '#411581',
  primary600: '#4c1d89',
  primary500: '#54218e',
  primary400: '#6c429e',
  primary300: '#8562ae',
  primary200: '#a68fc5',
  primary100: '#c9bbdc',
  primary50: '#eae3f1',
  secondary900: '#005457',
  secondary800: '#007781',
  secondary700: '#008a98',
  secondary600: '#009fb1',
  secondary500: '#00aec4',
  secondary400: '#1ebacc',
  secondary300: '#47c5d4',
  secondary200: '#7bd6e1',
  secondary100: '#afe6ec',
  secondary50: '#dff5f8'
};
exports.Colors = Colors;

var Variables = _styledComponents["default"].div.withConfig({
  displayName: "variables__Variables",
  componentId: "sc-1pvk2s4-0"
})([":root{---light-blue:#c9dff2;---medium-blue:#4B4EA0;---dark-blue:#3a3873;---gray-blue:#9fb0bf;---gold:#d9bc66;---black:#000;---white:#fff;---red:#D57272;}"]);

exports.Variables = Variables;