






export const ErrorNotification = function(message){
	return {
		message,
		options: {
			key: new Date().getTime() + Math.random(),
			variant: 'Error',
		},
	}
}

export const SuccessNotification = function(message){
	return {
		message,
		options: {
			key: new Date().getTime() + Math.random(),
			variant: 'Success',
		},
	}
}

export const WarningNotification = function(message){
	return {
		message,
		options: {
			key: new Date().getTime() + Math.random(),
			variant: 'Warning',
		},
	}
}
